import * as React from "react"
import Layout from "@components/Layout"
import Seo from "@components/SEO"
import Home from "@components/Home"

const IndexPage = () => (
  <Layout>
    <Home />
  </Layout>
)

export const Head = () => <Seo title="Home" />

export default IndexPage
