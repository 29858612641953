import Box from '@components/Box'
import Image from '@components/Image'
import { H2, Paragraph } from '@components/RichTextAndTypography'
import theme from '@themes/index'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { useEffect, useRef, useState } from 'react'
import bgMobile from '@images/about-the-game/bonsai/background-mobile.svg'
import { PetalRotation, PetalX, PetalY } from './styles'
import { debounce } from 'lodash'
import { useInView, useReducedMotion } from 'framer-motion'

const copy = {
  header: 'THE BONSAI',
  paragraph: 'After awaking on his sofa and discovering a Magnolia Bonsai growing in an old vase, the cracks begin to show in the reality which he’s created. Who placed it there while Adam slept, why that particular plant, and most importantly, why Adam?'
}

const PetalsAnimation = ({ petals, height, isResizing, isInView, reducedMotion }) => (
  petals?.map(petal => (
    <Box
      key={petal?.id}
      position="absolute"
      top="-10%"
      left={petal?.positions}
      width={[
        `${Math.round(petal?.width * .5)}px`,
        `${Math.round(petal?.width * .6)}px`,
        null,
        null,
        `${Math.round(petal?.width * .8)}px`,
        "fit-content"
      ]}
      zIndex="200"
    >
      <PetalX
        isResizing={isResizing}
        isInView={isInView}
        reducedMotion={reducedMotion}
        duration={petal?.duration}
        delay={petal?.delay}
        petalX={petal?.x}
      >
        <PetalY
          isResizing={isResizing}
          isInView={isInView}
          reducedMotion={reducedMotion}
          duration={petal?.duration}
          delay={petal?.delay}
          petalContainerHeight={height}
        >
          <PetalRotation
            isResizing={isResizing}
            isInView={isInView}
            reducedMotion={reducedMotion}
            duration={petal?.duration}
            delay={petal?.delay}
            petalRotation={petal?.rotation}
          >
            <Image image={petal?.image} alt="" />
          </PetalRotation>
        </PetalY>
      </PetalX>
    </Box>
  ))
)

const Bonsai = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerXL: file(relativePath: {eq: "about-the-game/bonsai/bonsai-bg-large.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
      bannerSmall: file(relativePath: {eq: "about-the-game/bonsai/bonsai-bg-small.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
      petal01: file(relativePath: {eq: "about-the-game/bonsai/petal-01.png"}) {
        id
        childImageSharp {
          gatsbyImageData(
            width: 95
            placeholder: NONE
          )
        }
      }
      petal02: file(relativePath: {eq: "about-the-game/bonsai/petal-02.png"}) {
        id
        childImageSharp {
          gatsbyImageData(
            width: 70
            placeholder: NONE
          )
        }
      }
      petal03: file(relativePath: {eq: "about-the-game/bonsai/petal-03.png"}) {
        id
        childImageSharp {
          gatsbyImageData(
            width: 41
            placeholder: NONE
          )
        }
      }
      petal04: file(relativePath: {eq: "about-the-game/bonsai/petal-04.png"}) {
        id
        childImageSharp {
          gatsbyImageData(
            width: 30
            placeholder: NONE
          )
        }
      }      
    }
  `)

  const desktopRef = useRef()
  const mobileRef = useRef()

  const isInViewDesktop = useInView(desktopRef)
  const isInViewMobile = useInView(mobileRef)

  const reducedMotion = useReducedMotion()

  const [desktopHeight, setDesktopHeight] = useState('100vh')
  const [mobileHeight, setMobileHeight] = useState('100vh')

  const [isResizing, setIsResizing] = useState(false)
  const setIsResizingFalseDebounce = debounce(() => setIsResizing(false), 100)

  const handleResize = () => {
    setIsResizing(true)

    if (desktopRef?.current?.offsetHeight)
      setDesktopHeight(desktopRef?.current?.offsetHeight)

    if (mobileRef?.current?.offsetHeight)
      setMobileHeight(mobileRef?.current?.offsetHeight)

    setIsResizingFalseDebounce()
  }

  useEffect(() => {
    if (window !== undefined && (desktopRef?.current || mobileRef?.current)) {
      handleResize()
      window.addEventListener('resize', handleResize)
    }

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const petals = [
    {
      id: data?.petal01?.id,
      image: getImage(data?.petal01),
      width: getImage(data?.petal01).width,
      positions: ['22%', null, '56%'],
      x: '-15vw',
      rotation: '-560deg',
      duration: '5s',
      delay: '0s'
    },
    {
      id: data?.petal02?.id,
      image: getImage(data?.petal02),
      width: getImage(data?.petal02).width,
      positions: ['26%', null, '58%'],
      x: '12vw',
      rotation: '420deg',
      duration: '4s',
      delay: '2.2s'
    },
    {
      id: data?.petal03?.id,
      image: getImage(data?.petal03),
      width: getImage(data?.petal03).width,
      positions: ['82%', null, '82%'],
      x: '-12vw',
      rotation: '-620deg',
      duration: '4.5s',
      delay: '.8s'
    },
    {
      id: data?.petal04?.id,
      image: getImage(data?.petal04),
      width: getImage(data?.petal04).width,
      positions: ['88%', null, '88%'],
      x: '8vw',
      rotation: '420deg',
      duration: '5.5s',
      delay: '1.4s'
    }
  ]

  const fade = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  }

  const logo = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: '6vh' }
  }

  const viewportConfig = { once: true, margin: "-10%" }

  return (
    <>
      <Box
        display={[null, null, 'none']}
        backgroundColor="brown"
        position="relative"
        overflow="hidden"
      >
        <Box
          backgroundImage={`url(${bgMobile})`}
          backgroundPosition="2% 50%"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          position="absolute"
          width="100%"
          height="100%"
          opacity=".5"
          style={{ transform: 'rotate(180deg)' }}
        />
        <Box
          background="linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), #FFC888"
          opacity='.5'
          position="absolute"
          width="100%"
          height="100%"
          ref={mobileRef}
        />
        <PetalsAnimation
          petals={petals}
          height={mobileHeight}
          isResizing={isResizing}
          isInView={isInViewMobile}
          reducedMotion={reducedMotion}
        />
        <Box
          px="48px"
          pt="32px"
          pb="64px"
          m="0 auto"
          maxWidth="460px"
          textAlign="center"
          position="relative"
          zIndex="300"
        >
          <H2 mb="8px">
            {copy.header}
          </H2>
          <Paragraph>
            {copy.paragraph}
          </Paragraph>
        </Box>
        <Image
          image={getImage(data?.bannerSmall)}
          alt="bonsai plant in room"
          style={{ zIndex: '100' }}
        />
      </Box>
      <Box
        width="100%"
        position="relative"
        overflow="hidden"
        py={[null, null, "32px", null, "64px", null, '128px']}
        display={['none', null, "flex"]}
        justifyContent="flex-end"
        alignItems="center"
        style={{ aspectRatio: '3840 / 1614' }}
        ref={desktopRef}
        animated
        initial="hidden"
        whileInView="visible"
        variants={fade}
        viewport={viewportConfig}
      >
        <Image
          image={getImage(data?.bannerXL)}
          alt=""
          position="absolute"
          width="100%"
          height="100%"
        />
        <PetalsAnimation
          petals={petals}
          height={desktopHeight}
          isResizing={isResizing}
          isInView={isInViewDesktop}
          reducedMotion={reducedMotion}
        />
        <Box
          width={[null, null, "38%", null, "35%"]}
          position="relative"
          pr={[
            null,
            null,
            "64px",
            '96px',
            `max(128px, calc(((100vw - ${theme.sizes.maxWidth.xxlarge}) * .5)))`
          ]}
          animated
          initial="hidden"
          whileInView="visible"
          variants={logo}
          viewport={viewportConfig}
        >
          <H2 mb={[null, null, '8px', '32px']}>
            {copy.header}
          </H2>
          <Paragraph
            fontSize={[null, null, null, null, null, null, '24px']}
            lineHeight={["24px", null, null, "28px", null, null, '32px']}
          >
            {copy.paragraph}
          </Paragraph>
        </Box>
      </Box>
    </>
  )
}

export default Bonsai