import Box from "@components/Box"
import styled, { keyframes } from "styled-components"

const x = (x) => keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(${x});
  }
`

const y = (bottom) => keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(calc(${bottom}px + 150%));
    opacity: 0;
  }
`

const rotate = (rotation) => keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(${rotation});
  }
`

export const PetalX = styled(Box)`
  animation-name: ${({ isResizing, isInView, reducedMotion, petalX }) => !reducedMotion && isInView && !isResizing ? x(petalX) : ''};
  animation-delay: ${({ delay }) => delay};
  animation-direction: alternate;
  animation-duration: ${({ duration }) => duration};
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0, -.2, .6, 1.5);
`

export const PetalY = styled(Box)`
  animation-name: ${({ isResizing, isInView, reducedMotion, petalContainerHeight }) => !reducedMotion && isInView && !isResizing ? y(petalContainerHeight) : ''};
  animation-delay: ${({ delay }) => delay};
  animation-duration: ${({ duration }) => duration};
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0, 0, .58, 1);
`

export const PetalRotation = styled(Box)`
  animation-name: ${({ isResizing, isInView, reducedMotion, petalRotation }) => !reducedMotion && isInView && !isResizing ? rotate(petalRotation) : ''};
  animation-delay: ${({ delay }) => delay};
  animation-direction: alternate;
  animation-duration: ${({ duration }) => duration};
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
`
