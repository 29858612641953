import Box from '@components/Box'
import Image from '@components/Image'
import { Anchor, H2, Section } from '@components/RichTextAndTypography'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'

const CTA = () => {
  const images = useStaticQuery(graphql`
    query {
      tiktok: file(relativePath: {eq: "CTA/TikTok-v2.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 684)
        }
      }
      steam: file(relativePath: {eq: "CTA/Steam.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 684)
        }
      }
      logo: file(relativePath: {eq: "CTA/logo-art-v2.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 1719
            placeholder: NONE
          )
        }
      }
    }
  `)

  const fade = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  }

  const float = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: '6vh' }
  }

  const viewportConfig = { once: true, margin: "-10%" }

  return (
    <Section
      width="100%"
      textAlign="center"
      pt={["64px", null, null, null, "128px"]}
      pb={["96px", null, null, null, "128px"]}
    >
      <Box
        mb={["32px", null, '64px']}
        mx={[null, null, null, null, "64px"]}
        animated
        initial="hidden"
        whileInView="visible"
        variants={fade}
        viewport={viewportConfig}
      >
        <Image
          image={getImage(images?.logo)}
          alt="Hell is Others logo"
        />
      </Box>
      <H2
        fontSize={["24px", null, "32px", null, '40px']}
        lineHeight={["28px", null, "38px", '47px']}
        maxWidth={["360px", null, 'initial']}
        m={["0 auto 32px", null, "0 auto 64px"]}
        animated
        initial="hidden"
        whileInView="visible"
        variants={float}
        viewport={viewportConfig}
      >
        WELCOME TO THE NEIGHBORHOOD
      </H2>
      <Box
        maxWidth="1800px"
        m="0 auto"
        px={["32px", null, "48px", null, '64px']}
        display="flex"
        alignItems={['center']}
        justifyContent={[null, null, null, null, 'space-evenly']}
        flexDirection={["column", null, null, null, 'row']}
        gridRowGap={["8px", null, '24px']}
        gridColumnGap={[null, null, null, null, '32px']}
        animated
        initial="hidden"
        whileInView="visible"
        variants={float}
        viewport={viewportConfig}
      >
        <Anchor href="https://www.tiktok.com/@hellisothers">
          <Image image={getImage(images?.tiktok)} alt="TikTok banner" />
        </Anchor>
        <Anchor href="https://gsght.com/c/jckrpr">
          <Image image={getImage(images?.steam)} alt="Steam banner" />
        </Anchor>
      </Box>
    </Section>
  )
}

export default CTA