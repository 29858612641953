import Box from '@components/Box'
import Image from '@components/Image'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

const StyledImage = styled(Image)`
  aspect-ratio: 828 / 468;
  
  @media (min-width: 768px) {
    aspect-ratio: 1536 / 468;
  }
  
  @media (min-width: 1360px) {
    aspect-ratio: 2880 / 878;
  }

  @media (min-width: 1920px) {
    aspect-ratio: 3840 / 1016;
  }
`

const GameCapture = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerXL: file(relativePath: {eq: "about-the-game/game-capture/banner-xl.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
      bannerDesktop: file(relativePath: {eq: "about-the-game/game-capture/banner-desktop.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
      bannerTablet: file(relativePath: {eq: "about-the-game/game-capture/banner-tablet.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
      bannerMobile: file(relativePath: {eq: "about-the-game/game-capture/banner-mobile.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  const banner = withArtDirection(getImage(data?.bannerXL), [
    {
      media: '(max-width: 767px)',
      image: getImage(data?.bannerMobile)
    },
    {
      media: '(max-width: 1359px)',
      image: getImage(data?.bannerTablet)
    },
    {
      media: '(max-width: 1919px)',
      image: getImage(data?.bannerDesktop)
    },
  ])

  const fade = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  }

  const viewportConfig = { once: true, margin: "-10%" }

  return (
    <Box
      animated
      initial="hidden"
      whileInView="visible"
      variants={fade}
      viewport={viewportConfig}
    >
      <StyledImage
        image={banner}
        alt="Game Capture"
      />
    </Box>
  )
}

export default GameCapture