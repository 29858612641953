import Box from '@components/Box'
import Image from '@components/Image'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import Community from '../Community'
import GameFeatures from '../GameFeatures'

const GameFeaturesCommunity = () => {
  const data = useStaticQuery(graphql`
    query {
      bgXL: file(relativePath: {eq: "game-features/background-xl-v2.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [JPG, WEBP]
            quality: 60
            breakpoints: [1360, 1920, 2880]
          )
        }
      }
      bgTablet: file(relativePath: {eq: "game-features/background-tablet-v2.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [JPG, WEBP]
            quality: 60
            breakpoints: [768, 1024, 1360, 1920]
          )
        }
      }
      bgMobile: file(relativePath: {eq: "game-features/background-mobile-v2.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [JPG, WEBP]
            quality: 60
            breakpoints: [768, 1024, 1360, 1920]
          )
        }
      }
    }
  `)

  const background = withArtDirection(getImage(data?.bgXL), [
    {
      media: '(max-width: 639px)',
      image: getImage(data?.bgMobile)
    },
    {
      media: '(max-width: 1023px)',
      image: getImage(data?.bgTablet)
    },
  ])

  const bgGradients = {
    mobile: "linear-gradient(5deg, rgba(0, 0, 0, 0.5) 18%, rgba(0, 0, 0, 0) 58%), linear-gradient(197deg, rgba(0, 0, 0, 0.8) 16%, rgba(0, 0, 0, 0) 30%)",
    tablet: "linear-gradient(5deg, rgba(0, 0, 0, 0.5) 18%, rgba(0, 0, 0, 0) 58%), linear-gradient(233deg, rgba(0, 0, 0, 0.8) 21%, rgba(0, 0, 0, 0) 66%)",
    desktop: "linear-gradient(5deg, rgba(0, 0, 0, 0.5) 18%, rgba(0, 0, 0, 0) 58%), linear-gradient(233deg, rgba(0, 0, 0, 0.8) 21%, rgba(0, 0, 0, 0) 66%)",
    xl: "linear-gradient(5deg, rgba(0, 0, 0, 0.5) 18%, rgba(0, 0, 0, 0) 58%), linear-gradient(233deg, rgba(0, 0, 0, 0.7) 21%, rgba(0, 0, 0, 0) 66%)"
  }

  return (
    <Box position="relative">
      <Image
        image={background}
        alt=""
        width="100%"
        height="100%"
        position="absolute"
        objectPosition='top left'
        zIndex="-100"
      />
      <Box
        width="100%"
        height="100%"
        position="absolute"
        background={[
          bgGradients.mobile,
          null,
          bgGradients.tablet,
          null,
          bgGradients.desktop,
          null,
          bgGradients.xl
        ]}
        zIndex="-100"
      />
      <Box
        width="100%"
        height="100%"
        position="absolute"
        background={[
          "linear-gradient(0deg, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0) 10%)",
          null,
          "linear-gradient(0deg, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0) 10%)",
          "linear-gradient(0deg, rgba(0, 0, 0, 1) 12%, rgba(0, 0, 0, 0) 28%)",
          null,
          "linear-gradient(0deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 24%)",
          "linear-gradient(0deg, rgba(0, 0, 0, 1) 8%, rgba(0, 0, 0, 0) 20%)",
        ]}
        backgroundPosition="bottom"
        zIndex="-100"
      />
      <GameFeatures />
      <Community />
    </Box>
  )
}

export default GameFeaturesCommunity