import Box from '@components/Box'
import Image from '@components/Image'
import { Anchor, H1, Paragraph, Section } from '@components/RichTextAndTypography'
import theme from '@themes/index'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

const StyledImage = styled(Image)`
  aspect-ratio: 828 / 1500;
  
  @media (min-width: 768px) {
    aspect-ratio: 1536 / 1954;
  }
  
  @media (min-width: 1360px) {
    aspect-ratio: 2880 / 2428;
  }

  @media (min-width: 1920px) {
    aspect-ratio: 3840 / 3218;
  }
`

const CTA = styled(Anchor)`
  transition: background, color .25s;

  @media (min-width: ${theme.sizes.maxWidth.medium}) {
    &:hover {
      background: ${theme.colors.tanDark};
      color: ${theme.colors.brown};
    }
  }
`

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      heroXL: file(relativePath: {eq: "hero/v2/hero-xl.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [JPG, WEBP]
            quality: 65
            breakpoints: [1360, 1920, 2400]
          )
        }
      }
      heroDesktop: file(relativePath: {eq: "hero/v2/hero-desktop.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [JPG, WEBP]
            quality: 65
            breakpoints: [1360, 1920, 2400]
          )
        }
      }
      heroTablet: file(relativePath: {eq: "hero/v2/hero-tablet.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [JPG, WEBP]
            quality: 65
            breakpoints: [768, 1024, 1360, 1920]
          )
        }
      }
      heroMobile: file(relativePath: {eq: "hero/v2/hero-mobile.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [JPG, WEBP]
            quality: 65
            breakpoints: [768, 1024, 1360, 1920]
          )
        }
      }
    }
  `)

  const images = withArtDirection(getImage(data?.heroXL), [
    {
      media: '(max-width: 767px)',
      image: getImage(data?.heroMobile)
    },
    {
      media: '(max-width: 1359px)',
      image: getImage(data?.heroTablet)
    },
    {
      media: '(max-width: 1919px)',
      image: getImage(data?.heroDesktop)
    },
  ])

  const container = {
    visible: {
      transition: {
        staggerChildren: .25
      }
    },
    hidden: {}
  }

  const float = {
    visible: {
      opacity: 1,
      y: 0
    },
    hidden: {
      opacity: 0,
      y: '6vh'
    }
  }

  const viewportConfig = { once: true, margin: "-10%" }

  return (
    <Section
      width="100%"
      overflow="hidden"
      backgroundColor="brown"
    >
      <StyledImage image={images} alt="" />
      <Box
        width="100%"
        pb="128px"
        px={["32px", null, "64px", "auto"]}
        mt={["-45%", null, "-35%", null, "-25%", null, "-18%"]}
        display="flex"
        justifyContent="center"
        background={`linear-gradient(0deg, ${theme.colors.brown} 70%, transparent)`}
        position="relative"
      >
        <Box
          maxWidth="1150px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          animated
          initial="hidden"
          whileInView="visible"
          variants={container}
          viewport={viewportConfig}
        >
          <CTA
            href="https://gsght.com/c/jckrpr"
            display="block"
            mb="64px"
            width="fit-content"
            color="tanMedium"
            bg="red"
            p="15px 62px"
            borderRadius="30px"
            fontWeight="700"
            fontSize="32px"
            animated
            variants={float}
          >
            BUY NOW
          </CTA>
          <H1
            mb="32px"
            animated
            variants={float}
          >
            AVAILABLE NOW
          </H1>
          <Paragraph
            color="tanDark"
            fontSize={['20px', null, '26px']}
            fontWeight={400}
            lineHeight={['32px', null, '36px']}
            animated
            variants={float}
          >
            A unique, multiplayer online extraction game, Hell is Others allows you to progress your character by way of customizable items you obtain for your apartment. Grow your own ammunition via blood-thirsty plants, explore the noir urban setting, confront The Others, and do your best to live to see another day… A day which never seems to come.
          </Paragraph>
        </Box>
      </Box>
    </Section>
  )
}

export default Hero