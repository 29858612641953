import Box from '@components/Box'
import Image from '@components/Image'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerXL: file(relativePath: {eq: "about-the-game/header/banner-xl.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
      bannerDesktop: file(relativePath: {eq: "about-the-game/header/banner-desktop.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
      bannerTablet: file(relativePath: {eq: "about-the-game/header/banner-tablet.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
      bannerMobile: file(relativePath: {eq: "about-the-game/header/banner-mobile.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
      title: file(relativePath: {eq: "about-the-game/header/title.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 568
            placeholder: TRACED_SVG
          )
        }
      }
    }
  `)

  const banner = withArtDirection(getImage(data?.bannerXL), [
    {
      media: '(max-width: 767px)',
      image: getImage(data?.bannerMobile)
    },
    {
      media: '(max-width: 1359px)',
      image: getImage(data?.bannerTablet)
    },
    {
      media: '(max-width: 1919px)',
      image: getImage(data?.bannerDesktop)
    },
  ])

  const fade = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  }

  const logo = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: '6vh' }
  }

  const viewportConfig = { once: true, margin: "-10%" }

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      justifyContent="center"
      position="relative"
      animated
      initial="hidden"
      whileInView="visible"
      variants={fade}
      viewport={viewportConfig}
    >
      <Image
        image={banner}
        alt=""
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
      />
      <Box
        maxWidth={["285px", null, "390px", null, 'initial']}
        my={["32px", null, "48px", null, "64px"]}
        animated
        initial="hidden"
        whileInView="visible"
        variants={logo}
        viewport={viewportConfig}
      >
        <Image
          image={getImage(data?.title)}
          alt="About the Game"
        />
      </Box>
    </Box>
  )
}

export default Header
