import { motion } from 'framer-motion'
import styled from 'styled-components'
import {
  background,
  layout,
  position,
  space
} from 'styled-system'

export const Video = styled(motion.video)`
  ${background}
  ${position}
  ${space}
  ${layout}
`
