import React, { useEffect, useRef } from 'react'
import ArtDecoContainer from '@components/ArtDecoContainer'
import Box from '@components/Box'
import { H2, Paragraph, Section } from '@components/RichTextAndTypography'
import { graphql, useStaticQuery } from 'gatsby'
import Image from '@components/Image'
import { getImage } from 'gatsby-plugin-image'
import theme from '@themes/index'
import botsWanderingWebm from '@images/game-features/video-bots-wandering.webm'
import botsWanderingMp4 from '@images/game-features/video-bots-wandering.mp4'
import elevatorDownWebm from '@images/game-features/video-elevator-down.webm'
import elevatorDownMp4 from '@images/game-features/video-elevator-down.mp4'
import furnishingHomeWebm from '@images/game-features/video-furnishing-home.webm'
import furnishingHomeMp4 from '@images/game-features/video-furnishing-home.mp4'
import plantsGrowingWebm from '@images/game-features/video-plants-growing.webm'
import plantsGrowingMp4 from '@images/game-features/video-plants-growing.mp4'
import PVPDodgeWebm from '@images/game-features/video-PVP-dodge.webm'
import PVPDodgeMp4 from '@images/game-features/video-PVP-dodge.mp4'
import { Video } from './styles'
import { useInView } from 'framer-motion'

const VideoPlayer = ({ webm, mp4 }) => {
  const ref = useRef(null)
  const isInView = useInView(ref)

  useEffect(() => {
    const video = ref?.current

    if (!video)
      return

    isInView ? video?.play() : video?.pause()
  }, [isInView, ref])

  return (
    <Video
      muted
      loop
      autoPlay
      playsInline
      display='block'
      width="100%"
      ref={ref}
    >
      <source src={webm} type="video/webm" />
      <source src={mp4} type="video/mp4" />
    </Video>
  )
}

const GameFeatures = () => {
  const data = useStaticQuery(graphql`
    query {
      title: file(relativePath: {eq: "game-features/title.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: TRACED_SVG
          )
        }
      }
    }
  `)

  const features = [
    {
      headline: "PVP",
      body: (
        <Paragraph>
          Stalking the same pathways you do are monsters that feel familiar to Adam, close yet so distant, maybe The Others are just like you. As fast as predator can become prey, make sure you’re using all of Adams’s senses to stay on top of the food chain. The Others not only want your blood, but to strip away all the precious items you’ve collected for their own purposes.
        </Paragraph>
      ),
      videoWebm: PVPDodgeWebm,
      videoMp4: PVPDodgeMp4
    },
    {
      headline: "PVE",
      body: (
        <Paragraph>
          This city is filled with the unknown evils of the world. In every alleyway, shop entrance, and cross walk, The Things lay in wait for the perfect time to strike in order to steal away the highly-valuable blood in your veins.
        </Paragraph>
      ),
      videoWebm: botsWanderingWebm,
      videoMp4: botsWanderingMp4
    },
    {
      headline: "BUILD YOUR SAFE PLACE",
      body: (
        <Paragraph>
          Customize Adam’s apartment with new objects and appliances to convert the tiny abode into a comfortable fortress. Besides simple decoration, every item has added benefits or perks for Adam while he is down in Century City trying to survive.
        </Paragraph>
      ),
      videoWebm: furnishingHomeWebm,
      videoMp4: furnishingHomeMp4
    },
    {
      headline: "GROW YOUR AMMO",
      body: (
        <Paragraph>
          Ensuring your plants grow big and healthy requires a constant supply of blood. But what if enhancing your crops yielded bigger, better results? By adding fertilizers, you can create a wide range of bullet-producing plants. Grow more powerful ammo, add unique features like bouncing bullets or silent varieties. The possibilities are endless...
        </Paragraph>
      ),
      videoWebm: plantsGrowingWebm,
      videoMp4: plantsGrowingMp4
    },
    {
      headline: "TICK TOCK",
      body: (
        <Paragraph>
          You have a limited time to do your business once down in the city. Lookup for an elevator and go back home before it’s too late, or you might just end up losing what’s in your pockets.
        </Paragraph>
      ),
      videoWebm: elevatorDownWebm,
      videoMp4: elevatorDownMp4
    }
  ]

  const fade = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  }

  const logo = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: '6vh' }
  }

  const viewportConfig = { once: true, margin: "-10%" }

  return (
    <Section
      width="100%"
      textAlign="center"
      position="relative"
      animated
      initial="hidden"
      whileInView="visible"
      variants={fade}
      viewport={viewportConfig}
    >
      <Box
        animated
        initial="hidden"
        whileInView="visible"
        variants={logo}
        viewport={viewportConfig}
      >
        <Image
          image={getImage(data?.title)}
          alt="Game Features"
          maxWidth={["285px", null, "390px", null, 'initial']}
          mt={["64px", null, '128px', null, null, null, '96px']}
        />
      </Box>
      <Box
        width="100%"
        pt={["64px", null, null, null, '16px', null, '200px']}
        pb={[null, null, '128px']}
        px={[
          "32px",
          null,
          null,
          '48px',
          "64px",
          `max(128px, calc(((100vw - ${theme.sizes.maxWidth.xxlarge}) * .5)))`
        ]}
        m="0 auto"
        display="flex"
        alignItems="center"
        flexDirection="column"
        gridRowGap="64px"
      >
        {features?.map((feature, i) => (
          <ArtDecoContainer
            key={`${i}-game-feature`}
            width="100%"
          >
            <Box
              px={[null, null, null, "64px"]}
              py={[null, null, null, "32px", "48px"]}
              display="flex"
              flexDirection={[
                'column',
                null,
                null,
                `${i % 2 !== 0 ? "row-reverse" : "row"}`
              ]}
              alignItems="center"
              justifyContent="center"
              gridColumnGap={[null, null, null, "64px", "128px"]}
              gridRowGap={["32px", null, "64px"]}
            >
              <Box
                flex={[null, null, null, "1"]}
                maxWidth={["calc(420px + 128px)", null, "calc(420px + 256px)", "35%", "380px"]}
                px={["64px", null, "128px", 'initial']}
              >
                <H2
                  fontSize="40px"
                  color="white"
                  mb="16px"
                >
                  {feature?.headline}
                </H2>
                {feature?.body}
              </Box>
              <Box
                flex={[null, null, null, "1"]}
                backgroundColor="brown"
                mx={["16px", null, "32px", "initial"]}
                borderStyle="solid"
                borderWidth="1px"
                borderColor="border"
                maxWidth="590px"
                position="relative"
                overflow="hidden"
                style={{ aspectRatio: "600 / 338" }}
              >
                <VideoPlayer webm={feature?.videoWebm} mp4={feature?.videoMp4} />
              </Box>
            </Box>
          </ArtDecoContainer>
        ))}
      </Box>
    </Section >
  )
}

export default GameFeatures