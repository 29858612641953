import React from 'react'
import Hero from './components/Hero'
import CTA from './components/CTA'
import AboutTheGame from './components/AboutTheGame'
import GameFeaturesCommunity from './components/GameFeaturesCommunity'

const Home = props => {
  return (
    <>
      <Hero />
      <AboutTheGame />
      <GameFeaturesCommunity />
      <CTA />
    </>
  )
}

export default Home