import styled from 'styled-components'
import {
  layout,
  position,
  space
} from 'styled-system'

export const StyledImg = styled.img`
  ${position}
  ${space}
  ${layout}
`