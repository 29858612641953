import Box from '@components/Box'
import { H2, Paragraph } from '@components/RichTextAndTypography'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import bgMobile from '@images/about-the-game/adam-smithson/background-mobile.svg'
import bgTablet from '@images/about-the-game/adam-smithson/background-tablet.svg'
import bgDesktop from '@images/about-the-game/adam-smithson/background-desktop.svg'
import Image from '@components/Image'
import { getImage } from 'gatsby-plugin-image'
import theme from '@themes/index'

const copy = {
  header: 'ADAM SMITHSON',
  paragraph: 'Adam Smithson does his best to exist in his tiny apartment, alone, high above Century City. His only companion is a bonsai tree, which he dutifully cares for—perhaps only to pass the time. Time which seems to have no beginning or end. But like everything else in the city, his plants require blood to survive. So Adam is forced to find a regular supply on the dark streets below. An elevator lowers Adam into the shadowy nightmare of the city where the hunt begins. In a place where blood is currency, and the only way out is down, Adam must survive and thrive the best he can while confronting others with the same goal in mind.'
}

const AdamSmithson = () => {
  const data = useStaticQuery(graphql`
    query {
      adam: file(relativePath: {eq: "about-the-game/adam-smithson/adam.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
          )
        }
      }
      city: file(relativePath: {eq: "about-the-game/adam-smithson/city.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
          )
        }
      }    
    }
  `)

  const adam = getImage(data?.adam)
  const city = getImage(data?.city)

  const container = {
    visible: {
      opacity: 1,
      transition: { when: "beforeChildren" }
    },
    hidden: { opacity: 0 }
  }

  const text = {
    visible: i => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.25 },
    }),
    hidden: { opacity: 0, y: '6vh' }
  }

  const scene = {
    visible: i => ({
      opacity: 1,
      transition: { delay: i * 0.25 },
    }),
    hidden: { opacity: 0 }
  }

  const adamVariant = {
    visible: { x: 0 },
    hidden: { x: '-5vw' }
  }

  const cityVariant = {
    visible: { x: 0 },
    hidden: { x: '5vw' }
  }

  const viewportConfig = { once: true, margin: "-10%" }

  return (
    <Box
      position="relative"
      width="100%"
      overflow="hidden"
      animated
      initial="hidden"
      whileInView="visible"
      variants={container}
      viewport={viewportConfig}
    >
      <Box
        backgroundImage={[`url(${bgMobile})`, null, `url(${bgTablet})`, null, `url(${bgDesktop})`]}
        backgroundPosition="top center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        position="absolute"
        width="100%"
        height="100%"
      />
      <Box
        display={['none', null, 'block']}
        backgroundColor='rgba(0, 0, 0, .5)'
        position="absolute"
        width="100%"
        height="100%"
      />
      <Box
        position="relative"
        display={[null, null, 'none']}
      >
        <Box
          pt="32px"
          pb="16px"
          px="32px"
          animated
          variants={text}
          custom={0}
        >
          <H2 mb="8px">{copy.header}</H2>
          <Paragraph>
            {copy.paragraph}
          </Paragraph>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          animated
          initial="hidden"
          whileInView="visible"
          variants={scene}
          viewport={viewportConfig}
          custom={0}
        >
          <Box
            mb="-20%"
            width="78%"
            animated
            variants={cityVariant}
          >
            <Image
              image={city}
              alt=""
            />
          </Box>
          <Box
            position="absolute"
            width="50%"
            right="56%"
            animated
            variants={adamVariant}
          >
            <Image
              image={adam}
              alt=""
            />
          </Box>
        </Box>
      </Box>
      <Box
        position="relative"
        display={['none', null, 'flex']}
      >
        <Box
          pl={[
            null,
            null,
            "64px",
            '96px',
            `max(128px, calc(((100vw - ${theme.sizes.maxWidth.xxlarge}) * .5)))`
          ]}
          pr={[null, null, '8%', null, '5%']}
          pt={[null, null, "32px", null, "64px", null, '128px']}
          pb={[null, null, "10%", null, "96px", null, "160px"]}
          width={[null, null, "56%", null, "46%", null, "44%"]}
          alignSelf={[null, null, 'center']}
          animated
          variants={text}
          custom={1}
        >
          <H2 mb={[null, null, '8px', '32px']}>
            {copy.header}
          </H2>
          <Paragraph
            fontSize={[null, null, null, null, null, null, '24px']}
            lineHeight={["24px", null, null, "28px", null, null, '32px']}
          >
            {copy.paragraph}
          </Paragraph>
        </Box>
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          width={[null, null, "44%", null, "54%", null, "56%"]}
          animated
          variants={scene}
          custom={0}
        >
          <Box
            position="absolute"
            ml={[null, null, "-18%", null, "15.5%", null, '15%']}
            mb={[null, null, "-22%", null, "-8%", null, '-10%']}
            width={[null, null, "74%", null, "42%", null, "44%"]}
            animated
            variants={cityVariant}
          >
            <Image image={city} alt="" />
          </Box>
          <Box
            position="relative"
            width={[null, null, "92%", null, "50%", null, "46%"]}
            mt={[null, null, "15%", null, "64px", null, '128px']}
            mb={[null, null, "-18%", null, "initial"]}
            animated
            variants={adamVariant}
          >
            <Image image={adam} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AdamSmithson