import { Section } from '@components/RichTextAndTypography'
import React from 'react'
import AdamSmithson from './components/Adam'
import Bonsai from './components/Bonsai'
import GameCapture from './components/GameCapture'
import Header from './components/Header'

const AboutTheGame = () => {
  return (
    <Section width="100%">
      <Header />
      <GameCapture />
      <AdamSmithson />
      <Bonsai />
    </Section>
  )
}

export default AboutTheGame
