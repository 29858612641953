import Box from '@components/Box'
import Image from '@components/Image'
import { Anchor, Blockquote, Section } from '@components/RichTextAndTypography'
import theme from '@themes/index'
import { graphql, Script, ScriptStrategy, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import tiktok from '@images/community/tiktok.svg'
import discord from '@images/community/discord.svg'
import steam from '@images/community/steam.svg'
import twitter from '@images/community/twitter.svg'
import { StyledImg } from './styles'
import React from 'react'

const Community = () => {
  const data = useStaticQuery(graphql`
    query {
      title: file(relativePath: {eq: "community/community-title.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 667
            placeholder: TRACED_SVG
          )
        }
      }
    }
  `)

  const links = [
    {
      image: discord,
      alt: "discord",
      href: "https://discord.com/invite/VhbGW6qzWX",
      id: `${discord}-social-link`,
    },
    {
      image: twitter,
      alt: "twitter",
      href: "https://twitter.com/HellisOthers_",
      id: `${twitter}-social-link`,
    },
    {
      image: tiktok,
      alt: "tiktok",
      href: "https://www.tiktok.com/@hellisothers",
      id: `${tiktok}-social-link`,
    },
    {
      image: steam,
      alt: "steam",
      href: "https://store.steampowered.com/app/964440/Hell_is_Others/",
      id: `${steam}-social-link`,
    },
  ]

  const fade = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  }

  const logo = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: '6vh' }
  }

  const viewportConfig = { once: true, margin: "-10%" }

  return (
    <Section
      width="100%"
      pt={["64px", null, "64px", null, "96px"]}
      pb={["16px", null, "48px", null, "96px", "70px"]}
      textAlign="center"
      animated
      initial="hidden"
      whileInView="visible"
      variants={fade}
      viewport={viewportConfig}
    >
      <Box
        animated
        initial="hidden"
        whileInView="visible"
        variants={logo}
        viewport={viewportConfig}
      >
        <Image
          image={getImage(data?.title)}
          alt="Community"
          maxWidth={["332px", null, "446px", null, 'initial']}
          mb={["48px", null, "64px", null, "16px"]}
        />
      </Box>
      <Box
        px={[
          "32px",
          null,
          null,
          '48px',
          "64px",
          `max(128px, calc(((100vw - ${theme.sizes.maxWidth.xxlarge}) * .5)))`
        ]}
      >
        <Blockquote
          className="tiktok-embed"
          cite="https://www.tiktok.com/@hellisothers"
          data-unique-id="hellisothers"
          data-embed-type="creator"
          m="0 auto !important"
          maxWidth="720px"
          minWidth="288px"
        >
          <section>
            <a target="_blank" href="https://www.tiktok.com/@hellisothers?refer=creator_embed">@hellisothers</a>
          </section>
        </Blockquote>
        <Script src="https://www.tiktok.com/embed.js" strategy={ScriptStrategy.idle} />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        gridColumnGap={['32px', null, '80px', null, '90px']}
        mt={['64px', null, '48px', null, '90px']}
      >
        {links?.map(link => (
          <Anchor key={link?.id} href={link?.href}>
            <StyledImg
              src={link?.image}
              alt={link?.alt}
              maxHeight={['22px', null, '38px', null, 'initial']}
            />
          </Anchor>
        ))}
      </Box>
    </Section>
  )
}

export default Community